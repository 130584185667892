<template>
  <div class="exclusiveClients-view">
    <div class="d-flex justify-content-between flex-wrap-y align-items-center">
      <div class="operation-left" v-if="customerState == 2">
        <el-button  @click="dialogTableVisible = true,dialogType = '移至成员'">移至成员</el-button>
        <el-button @click="toMonopoly()">抢为独占</el-button>
        <el-button type="primary" @click="toEdit" plain>编辑</el-button>
      </div>
      <div class="operation-left" v-else-if="customerState == 1" >
        <el-button  @click="dialogTableVisible = true,dialogType = '移至成员'">移至成员</el-button>
        <el-button  @click="turnOverShare">移至共享</el-button>
        <el-button type="primary" @click="toEdit" plain>编辑</el-button>
      </div>
      
    </div>
    <commonTable :tableData="tableData" :paginationVisible="false">
      <template v-slot:table>
        <el-table-column prop="customerId"  align="center" width="80" label="序号" show-overflow-tooltip/>
        <el-table-column prop="name" width="150" align="center" label="姓名" show-overflow-tooltip/>
        <el-table-column
          prop="phone"
          align="center"
          label="手机号码"
          width="120"
          show-overflow-tooltip
        />
        <el-table-column prop="email" align="center" label="邮箱" width="150" show-overflow-tooltip/>
        <el-table-column prop="project" align="center" label="项目名称" width="200" show-overflow-tooltip/>
        <el-table-column prop="address" align="center" label="客户地址" width="200" show-overflow-tooltip/>
        <el-table-column prop="personalWx" align="center" label="微信号" width="200" show-overflow-tooltip/>
        <el-table-column prop="companyWx" align="center" label="企业微信" width="150" show-overflow-tooltip/>
        <el-table-column prop="position" align="center" label="职位" width="80" show-overflow-tooltip/>
        <el-table-column prop="createCompanyName" align="center" label="公司" width="250" show-overflow-tooltip/>
        <el-table-column prop="lableResponseList" align="center" label="标签">
          <template slot-scope="scope">
            <div class="flex-c-c flex-wrap-y h100p">
              <span :style="{background:item.lableColor,
                            color: item.lableColor !== '#F5F5F6' ? '#ffffff' : '#454A4C',}" 
                            v-for="(item,index) in scope.row.lableResponseList" 
                            :key="index"
                            class="pl20 pr20 h30 lineheight30 d-block mb10 br5  mr10">
                            {{item.lableName}}
              </span>
            </div>
          </template>
        </el-table-column>
        
      </template>
    </commonTable>
    <div class="bef0 lh45 d-flex align-cen fs14 m-b-16">
      <span class="w70 textc bgf7fafa">备注</span>
      <input type="text" placeholder="这里是备注内容"
              class="pl30 h45 b0 blef0 cA2"
              :value="customerRemark || '' "
              readonly
              style="width: calc(100% - 208px);outline:none;">
    </div>
    <!-- left -->
    <div class="d-flex justify-content-between">
      <div style="width:55%;height:600px;" class="bef0 d-flex flex-column">
        <div class="flex-a-c bgf7fafa h40 pl24 fs14 cA2 bbef0">
          跟进记录
        </div>
        <div class="flex1 bbef0 oyauto pr24 pb10 pl24" ref="followList">
          <div class="textc  pt10 fs14 cA2 hover_pointer mb10" @click="getFollowLog('up')" v-if="followLog && !noData && (followLog.length > 9 )">查看更多</div>
          <div class="d-flex comment-row mb20" 
                v-for="(item,index) in followLog"
                :key="index">
            <div class="img-bg-box w50 h50 braduis50p"
                  :style="'background-image: url('+(item.logo || 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/crm/card1_user.png')+')'">
            </div>
            <div class="flex1 pl20 pr20"> 
              <p class="fs14 cA2 mb20">{{item.followName}}</p>
              <div  class="flex-a-c">
                <div style="padding:10px 20px;" class="bge3e7 flex1 h100p d-flex fs14 pl20 cA1 br6">
                   
                    <div v-if="item.followRemark.type != 1" class="w500 font-white fs7 lineheight24">
                      {{item.followType | formatFollowType}}  {{  item.followRemark.data || item.followRemark }}
                    </div>
                </div>
                <div class="w164 pl20 fs14 cA3 flex1" >
                  {{item.createTime | setTime}}
                </div>
              </div>
              <p></p>
            </div>
          </div>
        </div>
        <div class="pt24 pb24 pl24 pr24 d-flex flex-column">
          <el-input v-model="followRemark" type="textarea" rows="3" show-word-limitsize="medium" class="w100p fs7 cA1" style="color: #17191A;font-size:14px" placeholder="请输入跟进记录"></el-input>
          <div class="flex-a-b-c w100p mt10">
            <div class="flex-a-b-c w440">
              <div class=" fs14 hover_pointer" 
                   :class="currentLogsType == item.id ? 'cblue' : 'cA2' "
                   v-for="(item,index) in logs_type" 
                   @click="changeType(item.id)"
                   :key="index">
                {{item.title}}
              </div>
              
            </div>
            <el-button type="primary" @click="saveFollow">保存</el-button>
          </div>
        </div>
      </div>

       <!-- right -->
      <div style="width:45%;margin-left:16px;min-height:600px" class="bef0 d-flex flex-column">
        <div class="flex-a-c bgf7fafa h40 pl24 fs14 cA2 bbef0">
          总成交金额
        </div>
        <div class="pt40">
          <el-form
          :model="moneyForm"
          ref="moneyForm"
          label-width="100px"
          label-position="left"
          class="moneyForm"
        >
          <el-form-item >
            <p class="cgray">录入成交金额<span class="corange">*</span></p>
            <el-input
              v-model="moneyForm.money"
              style="width: 240px; margin-right: 8px"
              type="number"
              @blur="checkNum"
              placeholder="请输入金额"
            ></el-input>
          </el-form-item>
          
          <el-form-item >
            <p class="cgray">备注</p>
            <el-input
              v-model="moneyForm.remark"
              style="width: 240px; margin-right: 8px"
              placeholder="请输入备注"
            />
          </el-form-item>
          <el-form-item>
            <p class="cgray">上传图片</p>
            <el-upload
              :action="uploadUrl"
              list-type="picture-card"
              :class="{ disabled: uploadDisabled }"
              :multiple="true"
              :limit="9"
              :on-progress="handProgress"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          
          <el-form-item>
            <el-button
              class="save"
              type="primary"
              @click="addMoneyLog()"
              >添加</el-button
            >
          </el-form-item>
        </el-form>
        
        </div>
        <div class="flex-a-c h100 pl24 fs14 cA2 btef0">
            成交金额：{{totalMoney}}元
        </div>
        <div class="w100p pl30 pb20 pr30 bbef0" v-for="(item,index) in moneyLog" :key="index">
          <div class="w100p flex-a-b-c fs14 cA2 pt20 pb30 ">
            <p>{{item.createTime | setTime}}</p>
            <p>{{item.createTime }}</p>
            <p>{{item.money | setMoney}}</p>
            <p>{{item.remark }}</p>
          </div>
          <div style="text-align: right;" v-if="item.logo"> 
            <el-upload
              :action="uploadUrl"
              :class="{'addMoneyLogs':true,isdelete:bossDelete}"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :multiple="true"
              :limit="9"
              :on-progress="handProgress2"
              :on-success="(response, file, fileList) => handleSuccess2(response, file, fileList,item)"
              :on-exceed="handUpDataErr"
              :on-remove="(file, fileList) => handleRemove2( file, fileList,item)"
              :file-list="item.photos"
            >
              <i class="el-icon-plus fs8"></i>
            </el-upload>
            <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <memberList :memberData="memberData"
                :title="dialogType"
                :dialogTableVisible="dialogTableVisible"
                :total="memberTotal"
                @dialogClose="dialogClose"
                @dialogCurrentChange="dialogCurrentChange"
                @turnOverMember="turnOverMember">

    </memberList>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import memberList from "@/components/common/memberList";
import { 
  getCustomerInfo,
  getCompanyStaffList,
  getFollowList,
  moveCustomer,
  addFollow,
  inputDealMoney,
  updBatchCustomerState,
  getInputDealListt
} from "@/api/client";
import { getDataTimeSec } from "@/utils";
export default {
  name: "exclusiveClients",
  components: {
    commonTable,
    memberList
  },
  data() {
    return {
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      tableData: [],
      multipleSelection: [],
      customerIds:'',
      loading:false,
      name:'',//查询条件 ： 电话名称
      followName:'',//查询条件 ： 销售员姓名
      dialogTableVisible:false, //成员弹窗
      dialogType:'移至成员',// 弹出框类型
      dialogPageNum:'',
      memberData:[] ,// 
      memberTotal: 0,
      cardId:'',
      logs_type:[
        {title:'电话拜访',id: '1'},
        {title:'微信拜访',id: '2'},
        {title:'手动添加',id: '3'},
        {title:'当面拜访',id: '4'},
        {title:'其他方式',id: '5'},
      ],
      followLog:[],
      currentLogsType:'1',
      followRemark:'',
      noData: false,
      followPage:1,
      isFollowLoading:false,
      moneyForm:{
        money: "",
        remark: "",
        photos:""
      },
      fileList:[],
      fileList2:[],
      totalMoney:0, //成交金额
      moneyLoading:false,
      moneyLog:[],
      customerRemark:'',
      isUpdate:false,
      isBoss: this.$store.state.loginRoot.userInfo,
      dialogVisible:false,
      dialogImageUrl:'',
      customerState:0,
      isLoading:true, //阻止用户重复点击
      notDown:false //滚动条是否下滑
    };
  },
  created(){
    this.cardId = this.$route.query.id
    //console.log(this.cardId)
    this.getClientList()
    this.getMember()
    this.getFollowLog()
  },
  watch:{
    followLog(){
      var container = this.$refs.followList;
      if(this.notDown){
        return
      }
      setTimeout(function() {
        container.scrollTop = container.scrollHeight;
      },100)
    }
  },
  filters:{
    formatFollowType (type) {
      var obj = {
        1: '电话拜访',
        2: '微信拜访',
        3: '手动添加',
        4: '当面拜访',
        5: '其他方式',
      }
      let str = ''
      if(obj[type] ){
        str = '[' + obj[type] + ']' +  ' ‧'
      }else {
        str = ''
      }
      return str
    },
    setTime(data){
     let date =  getDataTimeSec(data)
     return date
    },
    setMoney(data){
      return  data = '￥' + (data/100).toFixed(2)
    }
    
  },
  computed: {
    uploadDisabled(photos){
      return this.moneyForm.photos.length >= 9;
    },
    moneyLogDisabled(){
     return (val) => {
       console.log(val);
       return val.length > 8
     }
    },
    // 判断是否为boss
    bossDelete(){
     return this.isBoss.roleId ? false  :  true
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getClientList() {
      this.loading = true
      let data = {
        cardId:this.cardId
      };
      getCustomerInfo(data)
      .then((res) => {
        this.loading = false
        this.tableData = [res.data]
        this.customerRemark = res.data.customerRemark
        this.customerState = res.data.customerState
        this.getMoneyLog()
      })
      .catch(err=>{
        this.tableData = []
        this.loading = false
      })
    },
    getMember(){
      let data = {
        pageNum: this.dialogPageNum,
        queryType:'web'
      }
        getCompanyStaffList(data)
        .then((res)=> {
            this.memberData = res.data.list;
            //console.log(this.memberData)
            this.memberTotal = res.data.totalNumber
        });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getClientList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getClientList()
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    //弹出框分页
    dialogCurrentChange(val){
      this.dialogPageNum = val
      this.getMember()
    },
    //确认人员
    turnOverMember(val){
      if(!val){
        this.$message({
          title: "警告",
          message: "请先选择移交的成员",
          type: "warning",
        });
      }
      
      let data ={
        customerIds:this.tableData[0].customerId,
        ascriptionId:val,
        customerState: 1,
      }
      moveCustomer(data)
      .then(res =>{
        if(res.code == 200){
          this.$message({
            message: "移交成功",
            type: "success",
          });
          this.getClientList()
          this.dialogTableVisible = false
        }
      })
      .catch(err=>{
        if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
      })
    },
    turnOverShare(tyep){
      this.$confirm( '此操作会将客户移至共享池', "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let data ={
          customerIds:this.tableData[0].customerId,
          customerState: 2,
        }
        moveCustomer(data)
        .then(res =>{
          if(res.code == 200){
            this.$message({
              message: "移交成功",
              type: "success",
            });
            this.getClientList()
            this.dialogTableVisible = false
          }
        })
        .catch(err=>{
          if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
        })

      })
      .catch(() => {});
    },
    //独占客户
    toMonopoly(){
      this.$confirm(  '是否抢为独占？', "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let data ={
          customerIds:this.tableData[0].customerId,
        }
        updBatchCustomerState(data)
        .then(res =>{
          if(res.code == 200){
            this.$message({
              message: "独占成功",
              type: "success",
            });
            this.getClientList()
            this.dialogTableVisible = false
          }
        })
        .catch(err=>{
          if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
        })

      })
      .catch(() => {});
    },
    changeType(id){
      this.currentLogsType = id
    },
    toEdit(){
      let id = this.cardId
      this.$router.push('../customerInput?id=' + id)
    },
    getFollowLog(type){
      if(type == 'up'){
        this.notDown = true
      }
      let data = {
              cardId: this.cardId,
              pageNum: this.followPage,
          }
      getFollowList(data)
      .then(res=>{
        if(res.data){
          this.noData = false;
          res.data.map(val => {
            try{
              val.followRemark = JSON.parse(val.followRemark);
              
            }catch (e) {
              val.followRemark = {}
            }
          });

          if (this.followPage == 1) {
            this.followLog = res.data.reverse();
            console.log(this.followLog)
          }else {
            this.followLog.unshift(...res.data.reverse());
          }

          this.followPage = this.followPage +1;
        } else{
          this.noData = true;
        }
      })
    },
    saveFollow(){
      if(this.isLoading == false){
        return
      }
      if(this.followRemark == ''){
        
        this.$message({
          message:'请先输入跟进记录',
          type:'warning'
        })
        return
      }
      
      this.isLoading  = false
      let data = {
        cardId: this.cardId,
        followType: this.currentLogsType,
        followRemark: this.followRemark
      }
      
      addFollow(data)
      .then(res=>{
        setTimeout(()=>{
          this.isLoading  = true
        },500)
        this.followPage = 1;
        this.getFollowLog();
        this.isFollowLoading = false;
        this.followRemark = '';
      })
      .catch((err)=>{
        this.isLoading  = true
      })
    },

    handleRemove(file, fileList) {
      this.moneyForm.photos = "";
    },
    handProgress(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      console.log(fileList)
      let arr = []
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: this.ossUrl + item.response.data,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.moneyForm.photos = arr;
      //console.log(this.moneyForm.photos)
    },


    handleRemove2( file, fileList,item) {
      let arr = fileList.map(item=>{
        return item.url
      })
      arr = arr.join(",")
      console.log(arr)
      let param = {
        inputDealId:item.inputDealId,
        photos:arr
      }
      this.addMoneyLog(param,'delete')
    },
    handProgress2(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    handleSuccess2(response, file, fileList,item) {
      this.$message.closeAll();
      this.isUpdate = true;
      let arr = item.photos.map(item=>{
        return item.url
      })
      arr.push(this.ossUrl + response.data)
      arr = arr.join(",")
      console.log(arr)
      let param = {
        inputDealId:item.inputDealId,
        photos:arr
      }
      this.addMoneyLog(param)
    },
    handUpDataErr(err, file, fileList){
      this.$message({
          message: "最多只可上传九张图片！",
          type: "warning",
        });
    },
    checkNum(){
      if(this.moneyForm.money < 0){
        this.$message({
          message: "请输入正确的金额",
          type: "warning",
        });
        return this.moneyForm.money = 0
      } 
    },
    // 添加成交记录
    addMoneyLog(datas,type){
      if(this.isLoading == false){
        return
      }
      this.isLoading  = false
      let param = ''
      if(datas){
        param = datas
      }else{
        let arr = []
        param = JSON.parse(JSON.stringify(this.moneyForm));
        if(this.moneyForm.photos){
          arr = this.moneyForm.photos.map((item) => item.url)          
          param.photos = arr.join(",")
        }
        if(this.moneyLoading) return;
        
        if(!param.money){
          return this.$message.error('请输入有效金额');
        }
        param.money = param.money*100;
        param.customerId = this.tableData[0].customerId;
      }
      this.moneyLoading = true;
      inputDealMoney(param,{ showLoading: true })
      .then(res=>{
        setTimeout(()=>{
          this.isLoading  = true
        },500)

        if(res.code == 200){
          if(type !== 'delete'){
            this.$message({
              message: "添加成功",
              type: "success",
            });
          }else{
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
          
          this.moneyLoading = false;
          if(datas && datas.inputDealId){
            return
          }
          this.getMoneyLog();
          this.moneyForm = {
              money: '',
              remark: '',
              photos:[]
          };
          this.fileList = []
        }
      }).catch((err)=> {
        this.isLoading  = true
        if(err.code == 201 ){
          this.$message.error(err.message)
        }
          this.moneyLoading = false;
      })
    },
    getMoneyLog(){
      let data = {
          customerId: this.tableData[0].customerId,
          pageNum: this.moneyPage,
        }
      getInputDealListt(data)
      .then( res=> {
        var totalMoney = 0;

        res.data && res.data.map(val=>{
            totalMoney += parseFloat(val.money);
            val.photos = val.photos ? (val.photos = val.photos.split(',').map((item, index) => {
                return {
                  url: item,
                  uid: index,
                };
              })
            ) : [];
        });
        this.totalMoney = (totalMoney/100).toFixed(2);
        this.moneyLog = res.data || [];
          
        console.log('this.moneyLog',this.moneyLog)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #51cbcd;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.bef0{border: 1px solid #EEEFF0}
.lh45{line-height: 45px;}
.align-cen{
  align-items: center;
}
.fs14{
  font-size: 14px;
}
.m-b-16{margin-bottom: 16px;}
.bgf7fafa{background: #f7fafa ;color: #737F80}
.b0{border: none !important}
.blef0{border-left: 1px solid #EEEFF0}
.img-bg-box {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.braduis50p {
    border-radius: 50%;
}

/deep/ .disabled .el-upload--picture-card {
    display: none;
}
/deep/ .el-form-item__content{
  margin-left: 24px !important;
}
/deep/ .addMoneyLogs .el-upload--picture-card{
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-right: 7px;
}
/deep/ .addMoneyLogs .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/ .isdelete .el-upload-list__item-actions .el-upload-list__item-delete{
  display: none;
}
/deep/ .isdelete .el-icon-close-tip{
  display: none;
}
/deep/ .addMoneyLogs  .el-upload-list__item-status-label{
  display: none;
}
/deep/.el-textarea__inner{
  color: #17191A;
  font-size: 14px;
}
</style>